$medium: 1200px;
$small: 500px;

.full-scr-flex-container {
  height: 100vh;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 6vh;
}

.relative-container {
  position: relative;
}

.big-title {
  font-size: 64px;
  font-weight: 500;
  text-align: center;
  max-width: 908px;
}

.link-btn-group {
  display: flex;

  > * + * {
    margin-left: 20px;
  }
}

.link-btn {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 42px;
  padding: 0 18px 1px 24px;
  position: relative;
  font-size: 16px;
  font-weight: normal;
  border-radius: 24px;
  border: 1px solid var(--black);
  overflow: hidden;

  svg {
    margin-left: 3px;
    stroke: var(--black);
  }

  &::before {
    content: '';
    display: block;
    width: 0%;
    height: 100%;
    aspect-ratio: 1;
    border-radius: 24px;
    background-color: var(--black-3);
    position: absolute;
    top: 0;
    left: 0;
    transition: 1s;
  }

  &:hover::before {
    width: 100%;
  }
}

@media screen and (max-width: $medium) {
  .full-scr-flex-container {
    padding: 32px;
    padding-bottom: 6vh;
  }

  .big-title {
    padding-bottom: 24px;
  }
}

@media screen and (max-width: $small) {
  .full-scr-flex-container {
    padding-bottom: 0;
  }

  .big-title {
    font-size: 48px;
    padding-bottom: 16px;
  }

  .link-btn-group {
    flex-direction: column;

    > * + * {
      margin-left: 0;
      margin-top: 16px;
    }
  }
}
