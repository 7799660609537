:root {
  --bg-white-1: #eeedeb;
  --bg-white-1-12: rgba(236, 231, 225, 0.12);

  --yellow-1: #c7bdaa;
  --yellow-1-36: rgba(199, 189, 170, 0.36);
  --yellow-2: #d4cdbf;

  --white-2-70: rgba(251, 251, 253, 0.7);
  --white-2-40: rgba(251, 251, 253, 0.4);

  --grey-1: #d9d9d9;

  --black: #625548;
  --black-2: #7b7065;
  --black-3: rgba(98, 85, 72, 0.2);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*::selection {
  background-color: var(--black-2);
  color: var(--yellow-2);
}

body {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-white-1);
  color: var(--black);
}

::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-track {
  background-color: var(--bg-white-1);
}

::-webkit-scrollbar-thumb {
  background-color: var(--black);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--black);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cormorant {
  font-family: 'Cormorant Garamond', serif;
}

a {
  text-decoration: none;
  color: var(--black);
}
